import React from "react";
import ReactDOM from "react-dom";
import Keycloak from "keycloak-js";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import App from "./app";
import * as serviceWorker from "./serviceWorker";

const authClient = new Keycloak("/keycloak.json");

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={authClient}
      initOptions={{
        onLoad: "login-required",
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
      }}
    >
      <App />
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
