import React, { Suspense } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import theme from "./theme";
import useAuth from "./hooks/use-auth";

const ProductAssets = React.lazy(() => import("./pages/product/assets"));
const Login = React.lazy(() => import("./pages/login"));

export default function App() {
  const { logged, login } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Router>
        <Suspense fallback={<div>...</div>}>
          <Switch>

            <Route path="/product/assets">
              <ProductAssets />
            </Route>

            <Route exact path="/">
              <Redirect to="/product/assets" />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}
