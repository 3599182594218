import { useState, useEffect } from "react";

import { useKeycloak } from "@react-keycloak/web";

export default function useAuth() {
  const profile = useState({});
  const [keycloak, initialized] = useKeycloak();
  const authenticated = initialized && !!keycloak.authenticated;

  useEffect(() => {
    if (authenticated) {
      keycloak
        .loadUserProfile()
        .then((profile) => console.log("profile", profile))
        .catch((err) => console.log("error on fetch profile", err));
    }
  }, [keycloak, authenticated]);

  return {
    initialized,
    authenticated,
    profile,
    token: keycloak.token,
    login: keycloak.login,
    logout: keycloak.logout,
  };
}
