import { createMuiTheme } from "@material-ui/core/styles";
import { deepPurple, purple } from "@material-ui/core/colors";

// Create a theme instance.
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Raleway",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
    ].join(","),
  },
  palette: {
    primary: deepPurple,
    secondary: purple,
  },
});

export default theme;
